import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Button,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

export default function CancellationPolicyDialog({ open, onClose, NextClick }) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold" }}>Class Guidelines</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" sx={{ fontWeight: 300, mb: 1 }}>
          Please read the following rules carefully before proceeding:
        </Typography>

        <Typography
          variant="h6"
          sx={{ color: "#1E90FF", textAlign: "center", mt: 3 }}
        >
          Class Attendance Rules (DDS Requirements)
        </Typography>
        <List sx={{ pl: 2, backgroundColor: "#f8f8f8", p: 1, borderRadius: 1 }}>
          <ListItem>
            <ListItemText
              primary={
                <>
                  <strong>Zoom Required:</strong> Download "ZOOM Workplace" on
                  your device before class (if required).
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  <strong>Camera & Audio:</strong> A working camera, microphone,
                  and stable internet are mandatory.
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  <strong>Full Attention Required:</strong> No distractions - no
                  working, driving, sitting in cars, shopping, TV, or substance
                  use.
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  <strong>Punctuality Matters:</strong> Log in 10-15 minutes
                  before class; tardiness is not allowed. Contact us if you
                  don't receive the link an hour before class.
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  <strong>Pre-Class Requirements:</strong> Complete the
                  contract, student info, and assessment before class or
                  reschedule.
                </>
              }
            />
          </ListItem>
        </List>
        <Typography
          variant="body2"
          color="error"
          sx={{ textAlign: "center", fontWeight: "bold", mt: 2 }}
        >
          Failure to follow these rules will result in removal from the class,
          forfeiture of fees, and the need to repay and restart the course.
        </Typography>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
          }
          label="I accept attendance rules"
          sx={{ fontWeight: 300 }}
        />
        <Stack sx={{ m: 2 }} justifyContent="flex-end" direction="row">
          <Button variant="contained" disabled={!isChecked} onClick={NextClick}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
