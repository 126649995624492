import { Link, useLocation, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Box,
    Button,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    Container,
} from "@mui/material";

// project imports
// import MainCard from 'components/MainCard';

// third-party

// assets
import completed from "../../assets/completed.png";
import "./success.css";

// ==============================|| CHECKOUT CART - ORDER COMPLETE ||============================== //

const SuccessPage = () => {
    const locations = useLocation();

    const { data } = locations.state || {};

    console.log(`epic data 😀😀😀 - `, data);

    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    // Check if productid: 1 exists in courseid array
    const hasProduct1 = data?.response?.courseid?.some(course => course.productid === 1);

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "100vh" }}
        >
            <Grid item>
                {/* <MainCard border={false}> */}
                <Stack spacing={2} alignItems="center">
                    <Box sx={{ position: "relative", width: { xs: 300, sm: 400 } }}>
                        <img
                            src={completed}
                            alt="Order Complete"
                            style={{ width: "inherit" }}
                        />
                    </Box>
                    <Typography variant={matchDownMD ? "h3" : "h1"} align="center">
                        Thank you for your purchase!
                    </Typography>

                    {/* Special Offer Section */}
                    {hasProduct1 && (
                        <Box sx={{ p: 2, backgroundColor: "#ffeb3b", borderRadius: "8px" }}>
                            <Typography variant="h6" align="center" color="black">
                                <strong>📅 Important Next Step: Schedule Your Driving Lessons</strong>
                            </Typography>
                            <Typography align="center" color="black">
                                🚨 Summer spots are filling up fast! To ensure you get your preferred time slots, <br /> please call us as soon as possible to schedule your lessons.
                            </Typography>
                            <Typography align="center" color="black" sx={{ mt: 1, fontWeight: "bold" }}>
                                📞 Call: 770-274-4223
                            </Typography>
                        </Box>
                    )}


                    <Grid container direction={"row"} spacing={2}>
                        {data &&
                            data?.response?.courseid?.map((val) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        lg={3}
                                        sx={{ marginBottom: { xs: "20px" } }}
                                    >
                                        <div className="container">
                                            <div className="card_box">
                                                <span></span>
                                                <Stack
                                                    direction={"column"}
                                                    justifyContent={"center"}
                                                    alignItems={"center"}
                                                    sx={{ height: "100%" }}
                                                >
                                                    <Typography
                                                        sx={{ color: "white !important" }}
                                                        variant="h5"
                                                    >
                                                        ${val?.amount}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ color: "white !important" }}
                                                        variant="h6"
                                                    >
                                                        {val?.productname}
                                                    </Typography>
                                                </Stack>
                                            </div>
                                        </div>
                                    </Grid>
                                );
                            })}
                    </Grid>

                    <Box sx={{ px: 2.5 }}>
                        <Typography align="center" color="textSecondary">
                            {/* Our Representative will be reaching out to you atleast a day before class by Text/Email  */}
                            You will receive a confirmation email with the credentials. Please
                            follow the steps outlined in the email regarding the next steps.
                            <br />
                            {/* <a href='https://www.student.1statlantaduischool.com/login' target='blank'>
                            https://www.student.1statlantaduischool.com/login
                            
                            </a> */}
                        </Typography>
                        {/* <Typography align="center" color="textSecondary">
                Your order ID:{' '}
                <Typography variant="subtitle1" component="span" color="primary">
                  {chance.guid()}
                </Typography>
              </Typography> */}
                    </Box>
                    {/* <Typography variant="h5" sx={{ py: { xs: 1, sm: 3 } }}>
                        (219) 404-5468
                    </Typography> */}
                    <Stack direction="row" justifyContent="center" spacing={3}>
                        <Button
                            onClick={() => {
                                navigate("/schedule-driving-lessons-2", {
                                    state: { number: 2 },
                                });
                            }}
                            // component={Link}
                            // to="/schedule-driving-lessons-2/1"
                            variant="outlined"
                            color="primary"
                            size={matchDownMD ? "small" : "medium"}
                        >
                            Continue Purchase
                        </Button>
                        {/* <Button
                            variant='contained'
                            color='success'
                            sx={{ color: 'white !important' }}
                            href='https://www.student.1statlantaduischool.com/login'
                            target='_blank'
                        >
                            Go to Dashboard
                        </Button> */}
                    </Stack>
                    <Typography
                        align="center"
                        color="textSecondary"
                        onClick={() => {
                            navigate("/", {
                                state: { number: 2 },
                            });
                        }}
                    >
                        <a
                            // href='https://projectsideaboxdigital.io.in/' target='_blank'
                            style={{
                                textDecoration: "underline !important",
                                textDecorationColor: "blue",
                                cursor: "pointer",
                            }}
                        >
                            Click Here
                        </a>{" "}
                        to Login and View your Order Details <br />
                        {/* <a href='https://www.student.1statlanimport { DirectionsIcon } from '@mui/icons-material/Directions';
taduischool.com/login' target='blank'>
                            https://www.student.1statlantaduischool.com/login
                            
                            </a> */}
                    </Typography>
                </Stack>
                {/* </MainCard> */}
            </Grid>
        </Grid>
    );
};

export default SuccessPage;
